import { P5CanvasInstance, SketchProps } from "@p5-wrapper/react";

type Origin = {
  x: number;
  y: number;
};

type Echo = {
  id: number;
  radius: number;
  origin: Origin;
  startAngle?: number;
};

type point = {
  x: number;
  y: number;
};

type MySketchProps = SketchProps & {
  isInverseBrah: boolean;
};

function intersectEchos(e1: Echo, e2: Echo): point[] {
  var centerdx = e1.origin.x - e2.origin.x;
  var centerdy = e1.origin.y - e2.origin.y;
  var R = Math.sqrt(centerdx * centerdx + centerdy * centerdy);
  if (!(Math.abs(e1.radius - e2.radius) <= R && R <= e1.radius + e2.radius)) {
    // no intersection
    return []; // empty list of results
  }
  // intersection(s) should exist

  var R2 = R * R;
  var R4 = R2 * R2;
  var a = (e1.radius * e1.radius - e2.radius * e2.radius) / (2 * R2);
  var r2r2 = e1.radius * e1.radius - e2.radius * e2.radius;
  var c = Math.sqrt((2 * (e1.radius * e1.radius + e2.radius * e2.radius)) / R2 - (r2r2 * r2r2) / R4 - 1);

  var fx = (e1.origin.x + e2.origin.x) / 2 + a * (e2.origin.x - e1.origin.x);
  var gx = (c * (e2.origin.y - e1.origin.y)) / 2;
  var ix1 = fx + gx;
  var ix2 = fx - gx;

  var fy = (e1.origin.y + e2.origin.y) / 2 + a * (e2.origin.y - e1.origin.y);
  var gy = (c * (e1.origin.x - e2.origin.x)) / 2;
  var iy1 = fy + gy;
  var iy2 = fy - gy;

  // note if gy == 0 and gx == 0 then the circles are tangent and there is only one solution
  // but that one solution will just be duplicated as the code is currently written
  return [
    { x: ix1, y: iy1 },
    { x: ix2, y: iy2 },
  ];
}

function sketch(p5: P5CanvasInstance<MySketchProps>) {
  let id = 0;
  let echos: Echo[] = [];
  let lastKeyInputs: string[] = [];
  const origins: Origin[] = [
    { x: window.innerWidth / 4, y: window.innerHeight / 2.5 },
    { x: window.innerWidth / 1.2, y: -(window.innerHeight / 2) },
    { x: window.innerWidth / 1.4, y: window.innerHeight / 1.6 },
  ];
  let speed = 0.4;
  let blink = 1;
  let arcMode = false;
  let inverseMode = false;
  let originsPlaced = 0;
  let fridgeNewt: any = null;
  let newtAngle = 0;
  const maxDist = Math.max(window.innerWidth / 2, 400);
  const respawnDistance = 75;
  const globalMaxEchos = 50;
  const initNumPerOrigin = 5;
  const maxOrigins = 5;

  const circleColor = [255, 255, 255];
  const arcColor = [255, 255, 255];
  const circleColorInv = [201, 28, 28];
  const arcColorInv = [20, 200, 20];

  const createEcho = (origin: Origin) => {
    return createEchoAtDistance(origin, 0);
  };

  const createEchoAtDistance = (origin: Origin, radius: number) => {
    id++;
    echos.push({ id, origin, radius });
  };

  const handleResize = () => {
    p5.resizeCanvas(window.innerWidth, window.innerHeight);
  };

  p5.preload = () => {
    fridgeNewt = p5.loadImage(`${process.env.PUBLIC_URL}/foul-wassie.png`);
  };

  p5.updateWithProps = (props: MySketchProps) => {
    if (props.isInverseBrah) {
      inverseMode = true;
    }
  };

  p5.windowResized = () => {
    handleResize();
  };

  p5.mouseClicked = () => {
    originsPlaced++;
    origins.push({ x: p5.mouseX, y: p5.mouseY });
    if (origins.length > maxOrigins) {
      origins.shift();
    }
  };

  p5.keyPressed = () => {
    lastKeyInputs.push(p5.key);
    if (lastKeyInputs.length > 12) {
      lastKeyInputs.shift();
    }
    if (lastKeyInputs.join("").includes("inversebrah")) {
      inverseMode = true;
      speed += 3;
    }

    if (p5.key === ")") {
      arcMode = !arcMode;
    }
    if (p5.keyCode === p5.UP_ARROW) {
      speed += 0.1;
    }
    if (p5.keyCode === p5.DOWN_ARROW) {
      speed -= 0.1;
    }
  };

  p5.setup = () => {
    p5.randomSeed(new Date().getTime());
    for (let j = 0; j < origins.length; j++) {
      for (let i = 0; i < initNumPerOrigin; i++) {
        createEchoAtDistance(origins[j], (i + 1) * 150);
      }
    }
    p5.imageMode(p5.CENTER);
    p5.createCanvas(window.innerWidth, window.innerHeight);
  };

  p5.draw = () => {
    p5.background("#111827");
    p5.noFill();
    // p5.fill("#111827");

    let toKill: number[] = [];
    echos.forEach((e) => {
      const col = inverseMode ? circleColorInv : circleColor;
      const maxAlpha = inverseMode ? 200 : 50;
      let alphaAmount = p5.map(e.radius, 0, maxDist, maxAlpha, 0);
      p5.stroke(col[0], col[1], col[2], alphaAmount);

      if (originsPlaced >= 5) {
        const angleToMouse = p5.atan2(p5.mouseY - e.origin.y, p5.mouseX - e.origin.x);
        e.startAngle = angleToMouse;
      } else {
        if (!e.startAngle) {
          const randomAngle = p5.random(0, 2 * Math.PI);
          e.startAngle = randomAngle;
        }
      }

      if (!arcMode) {
        p5.circle(e.origin.x, e.origin.y, e.radius * 2);
      }
      if (inverseMode) {
        p5.push();
        p5.translate(e.origin.x, e.origin.y);
        p5.rotate((p5.PI / 180) * newtAngle);
        p5.image(fridgeNewt, 0, 0, 32, 62);
        p5.pop();
        newtAngle += 0.1;
      }

      const arcCol = inverseMode ? arcColorInv : arcColor;
      p5.stroke(arcCol[0], arcCol[1], arcCol[2], alphaAmount * 1.5);
      p5.strokeWeight(2);
      p5.arc(e.origin.x, e.origin.y, e.radius * 2, e.radius * 2, e.startAngle! - 0.5, e.startAngle! + 0.5);
      p5.strokeWeight(1);

      if (alphaAmount <= 0) {
        toKill.push(e.id);
      }
    });

    echos = echos.filter((x) => !toKill.includes(x.id));

    echos = echos.map((e) => {
      let speedAmount = p5.map(e.radius, 0, maxDist, speed, speed * 0.5);
      e.radius += speedAmount;
      return e;
    });

    for (let i = 0; i < origins.length; i++) {
      if (echos.length >= globalMaxEchos) {
        continue;
      }

      const origin = origins[i];
      const echosByOrigin = echos.filter((e) => e.origin === origin);
      if (echosByOrigin.length === 0) {
        createEcho(origin);
        continue;
      }

      const newestEcho = echosByOrigin[echosByOrigin.length - 1];
      const randomFactor = p5.random(1, 2);
      if (newestEcho.radius > respawnDistance * randomFactor * (i + 1)) {
        createEcho(origin);
      }
    }

    if (!arcMode) {
      echos.forEach((e) => {
        if (e.radius < 400) {
          return;
        }
        p5.noStroke();
        p5.fill(255, 255, 255, 35);
        echos.forEach((e2) => {
          if (e.id === e2.id) {
            return;
          }
          const intersect = intersectEchos(e, e2);
          if (intersect.length === 0) {
            return;
          }
          intersect.forEach((i) => {
            p5.circle(i.x, i.y, 2);
          });
        });
      });
    }

    blink++;
    p5.noStroke();

    if (arcMode) {
      for (let i = 0; i < origins.length; i++) {
        const offset = i * 0.1;
        p5.fill(255, 255, 255, p5.map(p5.sin(blink * 0.01) + offset, -1, 1, 50, 0));
        p5.circle(origins[i].x, origins[i].y, 15);
      }
    }

    // render fps as text
    // p5.fill(255);
    // p5.textSize(16);
    // p5.text(p5.frameRate().toFixed(2), 10, 20);
  };
}

export default sketch;
