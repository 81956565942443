import { ReactP5Wrapper } from "@p5-wrapper/react";
import React, { useState } from "react";
import Marquee from "react-fast-marquee";
import sketch from "./sketch";

function App() {
  const [isPlaying, setIsPlaying] = useState(false);
  const [betaClicks, setBetaClicks] = useState(0);

  const wigglyBoiSpeed = 10;

  const onHover = () => {
    setIsPlaying(true);
  };

  const onLeave = () => {
    setIsPlaying(false);
  };

  const onClickBeta = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setBetaClicks(betaClicks + 1);
  };

  return (
    <>
      <div className="fixed w-full h-dvh bg-gray-900">
        <ReactP5Wrapper sketch={sketch} isInverseBrah={betaClicks > 4} />
      </div>
      <div className="fixed bg-transparent w-full h-dvh">
        <div className="h-screen flex flex-col gap-4 items-center md:justify-center mt-36 md:mt-0">
          <div className="flex items-center gap-3">
            <div className="text-white text-2xl">ECHO</div>
            <div
              className="px-1.5 py-1 rounded border border-gray-500 text-gray-400 text-xs select-none"
              onClick={onClickBeta}
            >
              BETA
            </div>
          </div>
          <div className="font-light text-gray-400 max-w-[350px] text-center">
            Echo is currently in an invite only-beta.
            <br />
            You’ll need an invite code to sign up.
          </div>
          <a
            href="https://app.echo.xyz/login?auto-true"
            className="font-light text-gray-100 border-b text-center hover:opacity-70"
          >
            Login -- or join the waiting list
          </a>
        </div>
      </div>
      <div className="fixed md:fixed md:justify-end flex flex-col gap-[7px] text-sm font-light items-left bottom-4 left-0 md:left-8 text-white text-center md:text-left p-4">
        <p className="text-gray-500 max-w-[38rem]">
          Echo’s use and transfer to any other app of information received from Google Accounts will adhere to the{" "}
          <a
            href="https://developers.google.com/terms/api-services-user-data-policy"
            className="border-b border-gray-500 hover:opacity-70"
          >
            Google API Services User Data Policy
          </a>
          , including the Limited Use requirements.
        </p>
      </div>
      <div className="fixed flex flex-col gap-[7px] text-sm bottom-64 md:bottom-8 left-0 md:left-auto right-0 md:right-36 text-white items-center md:items-end">
        <a href="https://x.com/echodotxyz" target="_blank" rel="noreferrer" className="w-fit border-b hover:opacity-70">
          Follow on X
        </a>
        <a
          href="https://app.echo.xyz/terms"
          target="_blank"
          rel="noreferrer"
          className="w-fit border-b hover:opacity-70"
        >
          Terms
        </a>
        <a
          href="https://app.echo.xyz/terms/privacy"
          target="_blank"
          rel="noreferrer"
          className="w-fit border-b hover:opacity-70"
        >
          Privacy
        </a>
      </div>
      <div className="fixed flex items-center justify-center bottom-36 md:bottom-8 left-0 md:left-auto right-0 md:right-12 text-white">
        <div
          className="text-xl leading-[1.15rem] tracking-wide w-fit overflow-hidden"
          onMouseOver={onHover}
          onMouseOut={onLeave}
        >
          <Marquee
            direction="left"
            loop={0}
            speed={wigglyBoiSpeed}
            play={isPlaying}
            className="overflow-hidden"
            style={{ width: 80, overflow: "hidden" }}
          >{`((((((((((((`}</Marquee>
          <Marquee
            direction="right"
            loop={0}
            speed={wigglyBoiSpeed}
            play={isPlaying}
            className="overflow-hidden"
            style={{ width: 80, overflow: "hidden" }}
          >{`))))))))))))`}</Marquee>
          <Marquee
            direction="left"
            loop={0}
            speed={wigglyBoiSpeed}
            play={isPlaying}
            className="overflow-hidden"
            style={{ width: 80, overflow: "hidden" }}
          >{`((((((((((((`}</Marquee>
          <Marquee
            direction="right"
            loop={0}
            speed={wigglyBoiSpeed}
            play={isPlaying}
            className="overflow-hidden"
            style={{ width: 80, overflow: "hidden" }}
          >{`))))))))))))`}</Marquee>
        </div>
      </div>
    </>
  );
}

export default App;
